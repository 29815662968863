.btn-settng-mdl {
  cursor: pointer;
  border-radius: 50%;
  padding: 1px 3px 3px 3px;
  position: relative;
  top: 45px;
  z-index: 1000000000000000000;
  transition: transform 1s ease-in-out,
}
.btn-settng-mdl:hover {
  transform: rotate(210deg);
}
.modal_card_settng-show {
  display: block;
  position: relative;
  top: 5px;
  left: 5px;
  transition: display 2s ease;
}
.modal_card_settng-hidd {
  display: none;
  transition: display 2s ease;
}