.header {
  background-color: rgb(208, 4, 4);
  color: rgb(255, 217, 0);
}

.isNotShow {
  display: none;
}

.active {
  background-color: #fff !important;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;
}
.leaflet-bar-part {
  display: none !important;
}

.geosearch {
  position: absolute !important;
  right: 0;
  width: 75%;
}

form {
  align-items: flex-end;
  align-content: flex-end;
}

.glass {
  width: 90%;
  border-radius: 5px !important;
}

.leaflet-top {
  width: 100%;
}
.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: 0px !important;
}

.reset {
  border-radius: 5px !important;
}
.color-icon-menu {
  color: rgb(102, 1, 1);
}

/* menu header CSS */
.menu-container {
  padding: 0px 0px;
  height: 100vh;
  background-color: rgb(208, 4, 4) !important;
}
/* .MuiPaper-root {
  background-color: rgb(208, 4, 4) !important;
} */
.menu-item {
  padding: 7px 0px 7px 10px;
  color:  rgb(208, 211, 0);
  cursor: pointer;
}
.menu-item:hover {
  color:  rgb(255, 255, 255);
  background-color: rgb(170, 0, 0);
}
.menu-item:hover>.color-icon-menu {
  color: rgb(234, 0, 0);
}
.selected-item {
  background-color: rgb(184, 1, 1);
}
.spacing {
  margin-left: 10px;
}
hr {
  height: 2px;
  background-color: rgb(184, 0, 0);
}
.menu-footer-version {
  color: white;
  text-align: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-shadow: 0px 0px 6px yellow;
}
.menubtn {
  cursor: pointer;
  padding: 0px 8px;
  color: rgb(255, 255, 0);
}
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  background: white;
}
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 7.5px 14px !important;
}

/* btn uploadfile */
input[type="file"]#file {
  top: -25px;
  margin: auto;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  z-index: -1;
}
#secondfile {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}


/* waves_2 */
.ocean_2 {
  height: 100%; /* change the height of the waves here */
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}

.wave_2 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23FF1E00'/%3E%3C/svg%3E");
  position: absolute;
  width: 200%;
  height: 100%;
  animation: wave_2 40s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
}

.wave_2:nth-of-type(2) {
  bottom: 0;
  animation: wave_2 20s linear reverse infinite;
  opacity: 0.5;
}

.wave_2:nth-of-type(3) {
  bottom: 0;
  animation: wave_2 23s -1s linear reverse infinite;
  opacity: 0.3;
}

@keyframes wave_2 {
  0% {transform: translateX(0);}
  25% {transform: translateX(-25%);}
  50% {transform: translateX(-50%);}
  75% {transform: translateX(-25%);}
  100% {transform: translateX(0);}
}